import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { ConsultantModel } from '../models/consultant.model';
import { Store } from '@ngrx/store';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { TimeEntryService } from '../services/time-entry.service';
import { ExpenseEntryService } from '../services/expense-entry.service';
import { Constants } from './constants.config';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class PagesRouteGuard implements CanActivate, CanActivateChild, CanDeactivate<CanComponentDeactivate>, OnDestroy {

    fullPageMode: boolean = null;
    loggedInUser: UserModel;
    currentUser: ConsultantModel;
    timeEntryIsUnsavedChangesPresent: boolean;
    expenseEntryIsUnsavedChangesPresent: boolean;

    constructor(
        private _store: Store,
        private _timeEntryService: TimeEntryService,
        private _expenseEntryService: ExpenseEntryService) {
        this._store.select(getLoggedInUser).subscribe((data) => {
            this.loggedInUser = data;
          });
        this._store.select(getCurrentUser).subscribe((data)=>{
            this.currentUser = data;
        })
        this._timeEntryService.entryIsUnsavedChangesPresent.subscribe((data)=>{
            this.timeEntryIsUnsavedChangesPresent = data;
        })
        this._expenseEntryService.entryIsUnsavedChangesPresent.subscribe((data)=>{
            this.expenseEntryIsUnsavedChangesPresent = data;
        })        
    }
    ngOnDestroy() {

    }
    canDeactivate(component: CanComponentDeactivate) {
            if (!component.canDeactivate &&
                (this.timeEntryIsUnsavedChangesPresent && component["entryPageType"]==Constants.NAV_TIME_ENTRY_ACTUALS) ||
                (this.expenseEntryIsUnsavedChangesPresent && component["entryPageType"]==Constants.NAV_EXPENSE_ENTRY)) {
                const confirmRedirect = window.confirm(Constants.MSG_CONFIRM_DISCARD_CHANGES);
                return confirmRedirect;
            }else {
                return true;
            }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        this.fullPageMode = true;
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        return this.canActivate(route, state);
    }

}
