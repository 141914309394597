export const enum ConstantFormStates {
    enabledAlways = 'ENABLED_ALWAYS',
    disabledAlways = 'DISABLED_ALWAYS',
}

export class Constants {

    // Nav-Items Constants
    static readonly NAV_TIME_ENTRY_ACTUALS = 'Time';
    static readonly NAV_FLAG = 'Flag';
    static readonly NAV_TIME_DICER = 'Time Dicer';
    static readonly NAV_EXPENSE_ENTRY = 'Expense';
    static readonly NAV_GROUP_PERSONAL = 'PERSONAL';
    static readonly NAV_GROUP_ANALYTICS = 'ANALYTICS';
    static readonly NAV_GROUP_REPORTS = 'REPORTS';
    static readonly NAV_GROUP_INTRANET = 'Intranet';
    static readonly NAV_SHORTCUTS_COOKIE = 'MIPS.shortcuts';
    static readonly NAV_PROJECT_DETAILS = 'PROJECT DETAILS';
    static readonly NAV_USEFUL_LINKS = 'USEFUL LINKS';
    static readonly NAV_TIME_ENTRY_2 = "Time Entry";
    static readonly NAV_WFH_REQUISITION_FORM = "WFH Requisition Form";
    static readonly NAV_WFH_REPORT = "WFH Report";
    static readonly NAV_PM_DASHBOARD = "PM Dashboard";
    static readonly NAV_LM_DASHBOARD = "LM Dashboard";
    static readonly NAV_INTRANET_HOME = "Intranet - Home";
    static readonly NAV_ADD_CLIENT = "Add Client"
    // static readonly NAV_DUMYY = 'Dummy';

    //Report Server Title Constants
    static readonly NAV_REPORT_COMPANY_SUMMARY = 'Company Summary';
    static readonly NAV_REPORT_PROJECT_DETAIL = 'Project Detail Report';
    static readonly NAV_REPORT_CONSULTANT_AVAILABILITY = 'Consultant Availability';
    static readonly NAV_REPORT_MONTHLY_REPORT = 'Monthly Expense Report';
    static readonly NAV_REPORT_CONSULTANT_DETAIL = 'Consultant Detail Report';
    static readonly NAV_REPORT_CONSULTANT_AVAILABILITY_CHART = 'Consultant Availability Chart';
    static readonly NAV_REPORT_LEAVE_DETAILS = 'Leave Details';

    // Snack Bar Constants
    static readonly SNACKBAR_VERTICALPOSITION = 'bottom';
    static readonly SNACKBAR_HORIZONTALPOSITION = 'right';
    static readonly SNACKBAR_TIME = 2000;
    static readonly SNACKBAR_MSG_LOGIN_CONTACTING_CONTROLLER = 'Contacting Thorogood Domain Controller for authentication! Please Wait!';
    static readonly SNACKBAR_MSG_LOGIN_VERIFYING_CREDENTIALS = 'Verifying Credentials!';
    static readonly SNACKBAR_MSG_LOGIN_RETRIEVING_DETAILS = 'Retrieving Consultant details!';
    static readonly SNACKBAR_MSG_SHORTCUT_ALREADY_EXISTS = 'Shortcut Already Exists';
    static readonly SNACKBAR_MSG_SHORTCUT_ADDED = 'Shortcut Added Successfully';
    static readonly SNACKBAR_MSG_SHORTCUT_REMOVED = 'Shortcut Removed Successfully';
    static readonly SNACKBAR_MSG_ENTRY_BAD_ENTRIES = 'Please correct the wrong entries';
    static readonly SNACKBAR_MSG_ENTRY_MULTIPLE_RATES = 'Multiple rates!';
    static readonly SNACKBAR_MSG_ENTRY_BLANK_ROW = 'Avoid coping blank row :(';
    static readonly SNACKBAR_MSG_ENTRY_MISSING_COLUMNS = 'Mandatory columns missing';
    static readonly SNACKBAR_MSG_ENTRY_SAVE_FALIED = 'Could not save entry details';
    static readonly SNACKBAR_MSG_ENTRY_DELETE_FALIED = 'Could not delete entry details';
    static readonly SNACKBAR_MSG_TIME_ENTRY_VALID_HOURS = 'Please enter valid hours';
    static readonly SNACKBAR_MSG_EXPENSE_ENTRY_VALID_MILEAGE = 'Please enter valid mileage';
    static readonly SNACKBAR_MSG_FLAG_SUMMARY_GRID_REFRESH = 'Refreshing Summary data!';
    static readonly SNACKBAR_MSG_TIME_DICER_BOTH_EMPTY = 'Both project and consultant list cannot be empty!';
    static readonly SNACKBAR_MSG_TIME_DICER_CONFIG_DELETED = 'The configuration has been deleted successfully';
    static readonly SNACKBAR_MSG_TIME_DICER_CONFIG_SAVED = 'The configuration has been saved successfully';
    static readonly SNACKBAR_MSG_TIME_DICER_CONFIG_UPDATED = 'The configuration has been updated successfully';
    static readonly SNACKBAR_MSG_SAVING_CHANGES = 'Saving changes!';
    static readonly SNACKBAR_MSG_WAITING_FOR_API_RESPONSE = 'Waiting for response!';

    // Pages Constants
    static readonly PAGES_LANDING_BIG_TEXT = 'Please give us a moment';
    static readonly PAGES_LANDING_SMALL_TEXT_REMEMBER = 'We are trying to remember you ...';
    static readonly PAGES_LANDING_SMALL_TEXT_FOUND = 'We found saved identity. Verifying';
    static readonly PAGES_LANDING_SMALL_TEXT_VERIFIED = 'Your identity is verified. Logging you in';
    static readonly PAGES_MAINTENANCE_BIG_TEXT = 'Closed for scheduled maintenance!';
    static readonly PAGES_MAINTENANCE_SMALL_TEXT_1 = 'We are sorry for the inconvenience.';
    static readonly PAGES_MAINTENANCE_SMALL_TEXT_2 = 'Please check back later.';
    static readonly PAGES_404_BIG_TEXT = '404 - Page Not Found :(';
    static readonly PAGES_404_SMALL_TEXT = 'The page you are looking for might have been removed or is temporarily unavailable.';
    
    // Messages for Users
    static readonly MSG_READ_ONLY_MODE = 'Currently in read - only mode!';
    static readonly MSG_CONFIRM_DISCARD_CHANGES = 'All your changes will be discarded. Do you still want to continue?';
    static readonly MSG_CONFIRM_RESET_ENTRY = 'Are you sure you want to reset the current view?';
    static readonly MSG_CONFIRM_DELETE_RECORDS = 'Are you sure you want to delete the selected records?';

    // Project Details Title Constants
    static readonly NAV_PROJECT_DETAILS_VIEW = 'View Project'
    static readonly NAV_PROJECT_DETAILS_ADD = 'Add Project'
    static readonly NAV_PROJECT_DETAILS_EDIT = 'View/Edit Project'

    // Constants for form blueprints
    static readonly PROPS_OBJECT_NAME = 'props';
    static readonly CHECKBOX_TOGGLE_EVENT_NAME = 'checkboxToggle';
    static readonly INPUT_FIELD_NAME = 'input';
    static readonly DROPDOWN_FIELD_NAME = 'dropdown';
    static readonly RADIO_FIELD_NAME = 'radio';
    static readonly BUTTON_FIELD_NAME = 'button';
    static readonly TEXT_FIELD_NAME = 'text';
    static readonly CHECKBOX_FIELD_NAME = 'checkbox';
    static readonly TEXTAREA_FIELD_NAME = 'textarea';
    static readonly NUMBER_FIELD_NAME = 'number';
    static readonly INTEGER_FIELD_NAME = 'integer';
    static readonly WHOLE_NO_FIELD_NAME = 'wholeNo';
    static readonly NATURAL_NO_FIELD_NAME = 'naturalNo';
    static readonly TELEPHONE_FIELD_NAME = 'tel';
    static readonly DATE_FIELD_NAME = 'date';
    static readonly FORM_VIEW_MODE = 'view';
    static readonly FORM_ADD_MODE = 'add';
    static readonly FORM_EDIT_MODE = 'edit';

    // Button Constants
    static readonly SAVE_BUTTON_NAME = 'Save';
    static readonly DELETE_BUTTON_NAME = 'Delete';
    static readonly SUBMIT_BUTTON_NAME = 'Submit';
    static readonly CLEAR_BUTTON_NAME = 'Clear';
    static readonly NEXT_BUTTON_NAME = 'Next';
    static readonly VIEW_BUTTON_NAME = 'View';

    //Form Type
    static readonly CONSUTANT_PROJECT_RATES_FORM = 'CPR';
    static readonly PROJECT_TASKS_FORM = 'PT';
    static readonly THEME_ALLOCATION_FORM = 'TA';
    static readonly PREPAYMENT_DETAILS_FORM = 'PD';
    static readonly PROJECT_DETAILS_EDIT_MODE = 'PDEM';
    static readonly PROJECT_DETAILS_ADD_MODE = 'PDAM';
    static readonly INVOICE_DETAILS_ADD_MODE = 'IDAM'
    static readonly INVOICE_DETAILS_EDIT_MODE = 'IDEM'
}
