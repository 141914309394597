<div class="">
  <form [formGroup]="form" (ngSubmit)="handleSubmit()" class="pt-1">
    <div  [style]="AddOuterGridStyle()" class="gap-2 pr-3">
      <div
        *ngFor="let formSection of sectionFields | keyvalue"
        [style.grid-area]="formSection.key"
        class="formSection gap-5 rounded-md"
        class="formSection gap-5 rounded-md"
      > 
      <div>
        <div *ngIf="true" [ngClass]="{ 
                                      'pl-4 pt-1': formSection.key !== 'headerDetails'
                                    }">
          <span class="font-semibold text-gray-600" style="font-size: 1em;">
            {{ getSectionDisplayName(formSection.key) }}
          </span>
        </div>

      </div>
        <div [style]="AddGridStyle(formSection.key)" *ngIf="formSection.key !== 'headerDetails'" 
          
        >
          <div
            *ngFor="let fieldCodeValue of formSection.value"
            [style.grid-area]="fieldCodeValue"
            style="min-width: 150px;"
            class="px-4"
            [ngClass]="{'py-3': fieldCodeValue === 'IN-CONT-ADD',
                        'py-2': getFieldDetails(fieldCodeValue).fieldType !== 'dropdown',
                        'py-0': getFieldDetails(fieldCodeValue).fieldType === 'dropdown'
                        }"
          >
            <ng-container
              *ngIf="getFieldDetails(fieldCodeValue); let fieldDetail"
              [ngTemplateOutlet]="this[fieldDetail.fieldType]"
              [ngTemplateOutletContext]="{ data: fieldDetail }"
            >
            </ng-container>
          </div>
        </div>

        <div [style]="AddGridStyle(formSection.key)" *ngIf="formSection.key === 'headerDetails'">
          <div
            *ngFor="let fieldCodeValue of formSection.value"
            [style.grid-area]="fieldCodeValue"
            
            style="min-width: 150px"
            class="px-4"
            [ngClass]="{'pr-0': fieldCodeValue === 'CL'
                        }"
          >
            <ng-container
              *ngIf="getFieldDetails(fieldCodeValue); let fieldDetail"
              [ngTemplateOutlet]="this[fieldDetail.fieldType]"
              [ngTemplateOutletContext]="{ data: fieldDetail }"
            >
            </ng-container>
          </div>
        </div>
      </div>
      </div>
      

    <!-- <div class="flex flex-row gap-5 pr-3">
      <button
        mat-raised-button
        type="button"
        [disabled]="checkSubmit(button.buttonName, button.buttonType, button.buttonPage)"
        [ngClass]="{
          'save-button': button.buttonName === 'Save',
          'submit-button': button.buttonName === 'Submit',
          'delete-button': button.buttonName === 'Clear' || button.buttonName === 'Delete',
          'next-button': button.buttonName === 'Next'
        }"
        [attr.data-type]="button.type"
        class="w-32 h-12 flex items-center justify-center disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer font-semibold rounded-md hover-effect hover:shadow-md transition-shadow duration-200"
        *ngFor="let button of blueprintProps.buttons;"
        (click)="handleButtonClick(button.buttonClickHandler, button.isValidationRequired, button.buttonName, button.buttonType, button.buttonPage)"
      >
        <div class="flex items-center justify-center gap-2 w-full h-full">
          <mat-icon class="h-6 w-6 pl-0">{{ getIconName(button.buttonName) }}</mat-icon>
          <div class="text-lg font-sans pr-1">{{ button.buttonName }}</div>
        </div>
      </button>
    </div> -->

    <div class="flex flex-row gap-5 pr-3 mb-1">
      <!-- Left-Aligned Buttons -->
      <ng-container *ngFor="let button of blueprintProps.buttons">
        <ng-container *ngIf="!['Save', 'Delete', 'Next'].includes(button.buttonName) || (button.buttonName === 'Save' && button.buttonType === 'PDEM')">
          <button
            mat-raised-button
            type="button"
            [disabled]="checkSubmit(button.buttonName, button.buttonType, button.buttonPage)"
            [ngClass]="{
              'save-button': button.buttonName === 'Save',
              'submit-button': button.buttonName === 'Submit',
              'delete-button': button.buttonName === 'Clear' || button.buttonName === 'Delete'
            }"
            [attr.data-type]="button.type"
            [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
            class="flex items-center justify-center disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer font-semibold rounded-md hover-effect hover:shadow-md transition-shadow duration-200 min-h-0 mb-0"
            (click)="handleButtonClick(button.buttonClickHandler, button.isValidationRequired, button.buttonName, button.buttonType, button.buttonPage)"
          >
            <div class="flex items-center justify-center gap-2 w-full h-full">
              <mat-icon
                [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">{{ getIconName(button.buttonName) }}</mat-icon>
              <div class="font-sans flex items-center"
                [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">
                {{ button.buttonName }}
              </div>
            </div>
          </button>
        </ng-container>
      </ng-container>
    
      <!-- Spacer to push right-aligned buttons -->
      <div class="flex-grow"></div>
    
      <!-- Right-Aligned Buttons -->
      <ng-container *ngFor="let button of blueprintProps.buttons">
        <ng-container *ngIf="['Delete', 'Next'].includes(button.buttonName) || (button.buttonName === 'Save' && button.buttonType !== 'PDEM')">
          <button
            mat-raised-button
            type="button"
            [disabled]="checkSubmit(button.buttonName, button.buttonType, button.buttonPage)"
            [ngClass]="{
              'save-button': button.buttonName === 'Save',
              'submit-button': button.buttonName === 'Submit',
              'delete-button': button.buttonName === 'Clear' || button.buttonName === 'Delete'
            }"
            [attr.data-type]="button.type"
            [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
            class="flex items-center justify-center disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer font-semibold rounded-md hover-effect hover:shadow-md transition-shadow duration-200 min-h-0 mb-0"
            (click)="handleButtonClick(button.buttonClickHandler, button.isValidationRequired, button.buttonName, button.buttonType, button.buttonPage)"
          >
            <div class="flex items-center justify-center gap-2 w-full h-full">
              <mat-icon
                [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">{{ getIconName(button.buttonName) }}</mat-icon>
              <div class="font-sans flex items-center"
                [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">
                {{ button.buttonName }}
              </div>
            </div>
          </button>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #input let-fieldDetail="data">
      <div class="relative border-b border-gray-400" style="height: 30px">
        <input
          [type]="fieldDetail.inputType"
          [formControlName]="fieldDetail.fieldCode"
          [className]="'input-field'"
          [name]="fieldDetail.fieldName"
          placeholder=" " 
          class="absolute left-0 top-0 w-full h-full bg-transparent"
        />
        <span class="floating-label absolute left-0 pointer-events-none"
              [ngClass]="{ 'disabled-input': form.get(fieldDetail.fieldCode).disabled }">
          {{ getFieldName(fieldDetail) }}
          <span *ngIf="fieldDetail.required && !form.get(fieldDetail.fieldCode).disabled" class="text-red-500">*</span>
        </span>
        <div >
          <mat-error class="hint-error_text--design gap-0"
                     style="font-size: 12px"
                     *ngIf="(form.get(fieldDetail.fieldCode).hasError('errorMessage') && fieldDetail.inputType === 'number' && (form.get(fieldDetail.fieldCode).value || form.get(fieldDetail.fieldCode).touched))">
            Value Can't be Negative or empty
          </mat-error>
        </div>
      </div>
    </ng-template>

    <ng-template #button let-fieldDetail="data">
          <button mat-raised-button type="button" [id]="fieldDetail.fieldCode" (click)="handleButtonClick(fieldDetail.buttonClickHandler, fieldDetail.isValidationRequired)" >
          {{ fieldDetail.fieldDisplayName }}
          </button>
    </ng-template>

    <ng-template #checkbox let-fieldDetail="data" let-fieldValue="fieldValue">
      <div class="relative border-gray-400 flex flex-row gap-3 items-center" style="height: 30px">
        <label class="pointer-events-none" [ngClass]="{ 'disabled-input': form.get(fieldDetail.fieldCode).disabled }"> <!--floating-label absolute left-0-->
          {{
          getFieldName(fieldDetail)
        }}<span *ngIf="fieldDetail.required && !form.get(fieldDetail.fieldCode).disabled" class="text-red-500">*</span></label>
        <input
          [type]="fieldDetail.inputType"
          [formControlName]="fieldDetail.fieldCode"
          [className]="'input-field'"
          (click)="onSelectionChange({
            selectedFieldCode: fieldDetail.fieldCode,
            selectedValue: getCheckboxToggleEventName()
          })"
          [checked]="checkboxMap.get(fieldDetail.fieldCode)"
        />
      </div>
    </ng-template>

    <ng-template #radio let-fieldDetail="data">
      <div class="relative border-gray-400 flex flex-col tb:flex-row gap-5 h-fit pt-6">
        <div class = "flex flex-row gap-1" *ngFor="let val of fieldDetail.fieldValues" >
          <input
            [type]="fieldDetail.inputType"
            [name]="fieldDetail.fieldName"
            [value]="val"
            [id]="val"
            [formControlName]="fieldDetail.fieldCode"
            (change)="onSelectionChange({
              selectedFieldCode: fieldDetail.fieldCode,
              selectedValue: val,
              fieldKeys: fieldDetail.fieldKey,
              fieldValues: fieldDetail.fieldValues,
              fieldType: fieldDetail.inputType
            })"
            style="opacity: 1 !important;"
          >
          <label [for]="val" class="self-center" [ngClass]="{ 'disabled-input': form.get(fieldDetail.fieldCode).disabled }">{{ val }}
            <span *ngIf="fieldDetail.required && !form.get(fieldDetail.fieldCode).disabled" class="text-red-500">*</span></label>
        </div>
      </div>
    </ng-template>

    <!-- <ng-template #textarea let-fieldDetail="data">
      <div class="relative border-gray-400">
          <span class="floating-label absolute left-0 pointer-events-none" [ngClass]="{ 'disabled-input': form.get(fieldDetail.fieldCode).disabled }">{{
            getFieldName(fieldDetail)
          }}<span *ngIf="fieldDetail.required && !form.get(fieldDetail.fieldCode).disabled" class="text-red-500">*</span></span>
            <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMaxRows="2"
              [formControlName]="fieldDetail.fieldCode"
              style="width: 100%; min-height: 33px;"
              class="border-b border-gray-400"
            ></textarea>
        </div>
    </ng-template> -->

    <ng-template #textarea let-fieldDetail="data">
      <div class="relative border-gray-400">
        <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMaxRows="2"
                  [formControlName]="fieldDetail.fieldCode"
                  placeholder=" " 
                  style="width: 100%; min-height: 33px;"
                  class="border-b border-gray-400"
                  
        ></textarea>
        <span class="floating-label absolute left-0 pointer-events-none" 
              [ngClass]="{ 'disabled-input': form.get(fieldDetail.fieldCode).disabled }">
          {{ getFieldName(fieldDetail) }}
          <span *ngIf="fieldDetail.required && !form.get(fieldDetail.fieldCode).disabled" class="text-red-500">*</span>
        </span>
      </div>
    </ng-template>

    <ng-template #dropdown let-fieldDetail="data">
      <ng-select
        (change)="
          onSelectionChange({
            selectedValue: $event,
            selectedFieldCode: fieldDetail.fieldCode
          })
        "
        [items]="
          isObject(fieldDetail.fieldValues) ? fieldDetail.fieldValues : []
        "
        [formControlName]="fieldDetail.fieldCode"
        [placeholder]="fieldDetail.placeholderValue"
        [ngClass]="{
                    'required': shouldDisplayAsterisk(fieldDetail),
                    'pb-0': ((fieldDetail.fieldCode === 'PR-CODE' || fieldDetail.fieldCode === 'PR-TYPE' && (form.get(fieldDetail.fieldCode).hasError('errorMessage'))) && mode === 'edit' ),
                    'pb-2': (fieldDetail.fieldCode === 'THM') || ((fieldDetail.fieldCode === 'CL' || fieldDetail.fieldCode === 'PR') && mode === 'edit'),
                    'min-w-48 pr-0': (fieldDetail.fieldCode === 'CL' && mode === 'edit')
                  }"
        [addTag]="fieldDetail.fieldCode === 'PR-CODE'"
        [addTagText]="'Add new value'"
      >
        <ng-template ng-label-tmp let-item="item" *ngIf="fieldDetail.fieldCode !== 'PR-CODE'">
          <span class="ng-value-label" [title]="item">{{ item }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" *ngIf="fieldDetail.fieldCode !== 'PR-CODE'">
          <span class="ng-option-label" [title]="item" >{{ item }}</span>
        </ng-template>

        <ng-template ng-label-tmp let-item="item" *ngIf="fieldDetail.fieldCode === 'PR-CODE'">
          <span class="ng-value-label" [title]="item">{{ item.label }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" *ngIf="fieldDetail.fieldCode === 'PR-CODE'">
          <span class="ng-option-label" [title]="item" >{{ item.label }}</span>
        </ng-template>
      </ng-select>

      <div>
        <mat-error class="hint-error_text--design" style="font-size: 12px;" 
                 *ngIf="(form.get(fieldDetail.fieldCode).hasError('errorMessage'))"
                >
                  PR-CODE can't be of more than 4 characters
        </mat-error>
      </div>
    </ng-template>

    <ng-template #date let-fieldDetail="data">
      <div class="relative flex flex-col justify-items-center">
        <div
          class="flex flex-row justify-between items-center border-b border-gray-400 w-full"
         >
         <!-- <mat-form-field> -->
          <input
            matInput
            [matDatepicker]="picker"
            readonly
            (click)="picker.open()"
            [formControlName]="fieldDetail.filterCode"
            (dateChange)="handleDateSelection(fieldDetail.fieldCode, $event)"
            [name]="fieldDetail.filterCode"
            class="pb-2"
          />
          <span class="floating-label absolute left-0 pointer-events-none">{{
            getFieldName(fieldDetail)
          }} <span *ngIf="fieldDetail.required && !form.get(fieldDetail.fieldCode).disabled" class="text-red-500">*</span></span>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
            style="position: absolute; left: 90%; padding-bottom: 8px"
          ></mat-datepicker-toggle>
          <!-- </mat-form-field> -->

          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
    </ng-template>
  </form>
</div>

<!-- add mode template for development -->
<!-- <form [formGroup]="form">
  <div>
      <div *ngFor="let fieldDetail of fieldDetails" class="px-2">
        <ng-container
          [ngTemplateOutlet]="this[fieldDetail.fieldType]"
          [ngTemplateOutletContext]="{ data: fieldDetail }"
        >
        </ng-container>
      </div>
  </div>
  
  <ng-template #input let-fieldDetail="data">
      <label>{{fieldDetail.fieldDisplayName}}</label>
      <input
          [type]="fieldDetail.inputType"
          [style.grid-area]="fieldDetail.fieldCode"
          [formControlName]="fieldDetail.fieldCode"
          [className]="'input-field'"/>
  </ng-template>
  
  <ng-template #textarea let-fieldDetail="data">
      <label>{{fieldDetail.fieldDisplayName}}</label>
      <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          [style.grid-area]="fieldDetail.fieldCode"
          [formControlName]="fieldDetail.fieldCode"
      ></textarea>
  </ng-template>
  
  <ng-template #dropdown let-fieldDetail="data">
    <ng-select
      (change)="onSelectionChange({ selectedValue: $event, selectedFieldCode: fieldDetail.fieldCode })"
      [items]="isObject(fieldDetail.fieldValues) ? fieldDetail.fieldValues : []"
      [formControlName]="fieldDetail.fieldCode"
      [placeholder]="fieldDetail.placeholderValue"
      [style.grid-area]="fieldDetail.fieldCode"
    ></ng-select>
  </ng-template>
</form> -->
