import { environment } from "environments/environment";

export class URLPaths {

    // API End Points
    static readonly URL_BASE = environment.apiURLEndpoint;
    // static readonly URL_DUMMY = URLPaths.URL_BASE + 'dummy/';
    static readonly URL_WFH_REQUISITION = URLPaths.URL_BASE + 'wfm_requisition/';
    static readonly URL_PROJECT = URLPaths.URL_BASE + 'project/';
    static readonly URL_PROJECT_TASK = URLPaths.URL_BASE + 'project_task/';
    static readonly URL_CONSULTANT_RATE_BY_PROJECT = URLPaths.URL_BASE + 'consultant_rate/';
    static readonly URL_CONSULTANT_DETAILS = URLPaths.URL_BASE + 'consultant_detail/';
    static readonly URL_PROJECT_DETAILS = URLPaths.URL_BASE + 'project_detail/';
    static readonly URL_CONTACT_DETAILS = URLPaths.URL_BASE + 'contact_detail/';
    static readonly URL_PROJECT_AND_CONTACT_DETAILS = URLPaths.URL_BASE + 'project_contact_detail/'
    static readonly URL_SALES_TAX_DETAILS = URLPaths.URL_BASE + 'sales_tax/';
    static readonly URL_TIME_ENTRY = URLPaths.URL_BASE + 'time_entry/';
    static readonly URL_TIME_DICER_CONFIG = URLPaths.URL_BASE + 'time_dicer_config/';
    static readonly URL_TIME_ENTRY_GRID_CONFIG = URLPaths.URL_BASE + 'time_entry_grid_config/';
    static readonly URL_FLAG_ENTRY = URLPaths.URL_BASE + 'flag/';
    static readonly URL_MONTH_FLAG_DEADLINE = URLPaths.URL_BASE + 'month_flag_deadline_for_consultant/';
    static readonly URL_WRITE_SILENCE = URLPaths.URL_BASE + 'write_silence/';
    static readonly URL_USER = URLPaths.URL_BASE + 'user/';
    static readonly URL_USER_PHOTO = URLPaths.URL_BASE + 'userPhoto/';
    static readonly URL_VALIDATE_TOKEN = URLPaths.URL_BASE + 'validateToken/';
    static readonly URL_LOGIN = URLPaths.URL_BASE + 'login/';
    static readonly URL_LOGIN_OPTIMIZED = URLPaths.URL_BASE + 'login_new/';
    static readonly URL_LOGOUT = URLPaths.URL_BASE + 'logout/';
    static readonly URL_EXPENSE_TYPE  = URLPaths.URL_BASE + 'expense_types/';
    static readonly URL_EXPENSE_ENTRY  = URLPaths.URL_BASE + 'expense_entry/';
    static readonly URL_EXPENSE_TYPE_DESCRIPTION = URLPaths.URL_BASE + 'expense_descriptions/';
    static readonly URL_IMMIGRATION_DETAILS = URLPaths.URL_BASE + 'consultant_immigration_details/';
    static readonly URL_EXPENSE_PAYMENT_METHOD = URLPaths.URL_BASE + 'expense_payment_method/';
    static readonly URL_CURRENCY = URLPaths.URL_BASE + 'currency/';
    static readonly URL_EXPENSE_PROJECT = URLPaths.URL_BASE + 'project_expense/';
    static readonly URL_EXPENSE_ENTRY_GRID_CONFIG = URLPaths.URL_BASE + 'expense_entry_grid_config/';
    static readonly URL_CLIENT_DETAILS = URLPaths.URL_BASE + 'clients/';
    static readonly URL_COMPANY_DETAILS = URLPaths.URL_BASE + 'company/';
    static readonly URL_PROJECT_DETAIL_TABLES = URLPaths.URL_BASE + 'project_details_tables/';
    static readonly URL_PROJECT_TYPES = URLPaths.URL_BASE + 'project_type/';
    static readonly URL_INVOICE_FORMATS = URLPaths.URL_BASE + 'invoice_format/';
    static readonly URL_PROMPT_PAYMENT_PERIOD_TYPES = URLPaths.URL_BASE + 'period_type/';
    static readonly URL_TYPES_OF_SERVICE = URLPaths.URL_BASE + 'project_details_types_of_services/';
    static readonly URL_PURCHASE_ORDER_NOS = URLPaths.URL_BASE + 'purchase_orders_for_project/';
    static readonly URL_CONSULTANT_PROJECT_RATES = URLPaths.URL_BASE + 'consultant_project_rates/';
    static readonly URL_PROJECT_TASKS = URLPaths.URL_BASE + 'project_tasks/';
    static readonly URL_PROJECT_THEME_ALLOCATION = URLPaths.URL_BASE + 'project_theme_allocation/';
    static readonly URL_PREPAYMENT_DETAILS = URLPaths.URL_BASE + 'project_prepayment_details/';
    static readonly URL_PERIOD_MONTHS = URLPaths.URL_BASE + 'period_months/';
    static readonly URL_PROJECT_THEMES = URLPaths.URL_BASE + 'themes/';
    static readonly URL_COUNTRY_DETAILS = URLPaths.URL_BASE + 'country_and_state/';
    static readonly URL_GET_CLENTS_FOR_ADD_CLIENT = URLPaths.URL_BASE + 'get_for_add_client/';

    // Images/GIFs paths
    static readonly PATH_USER_PHOTO_PLACEHOLDER = 'assets/images/userPhotoPlaceHolder.png';
    static readonly PATH_USERSEARCH_GIF = 'assets/images/searchAnimation.gif';
    static readonly PATH_USERFETCHINFO_GIF = 'assets/images/fetchInfo.gif';

    // Routes
    static readonly ROUTE_LOGIN = '/login';
    static readonly ROUTE_TIME_ENTRY_ACTUALS = '/mips/core/actuals/time';
    static readonly ROUTE_EXPENSE_ENTRY_ACTUALS = '/mips/core/actuals/expense';
    static readonly ROUTE_FLAG_ACTUALS = '/mips/core/actuals/flag';
    static readonly ROUTE_TIME_DICER_ACTUALS = '/mips/core/actuals/time-dicer';
    static readonly ROUTE_LANDING_PAGE = '/pages/landing';
    static readonly ROUTE_NOT_FOUND = '/not-found';
    static readonly ROUTE_ADD_CLIENT = '/mips/core/client/add-client'
    // static readonly ROUTE_DUMMY = '/mips/core/actuals/dummy';

    // Report Server Endpoints
    static readonly ROUTE_REPORT_SERVER = "https://reportserver.thorogood.com/ReportServer";
    static readonly ROUTE_REPORT_COMPANY_SUMMARY = "/mips/core/report-embed/companySummary";
    static readonly ROUTE_REPORT_PROJECT_DETAIL = "/mips/core/report-embed/projectDetailReport";
    static readonly ROUTE_REPORT_CONSULTANT_AVAILABILITY = "/mips/core/report-embed/consultantAvailability";
    static readonly ROUTE_REPORT_MONTHLY_REPORT = "/mips/core/report-embed/monthlyReport";
    static readonly ROUTE_REPORT_CONSULTANT_DETAIL_REPORT = "/mips/core/report-embed/consultantDetailReport";
    static readonly ROUTE_REPORT_CONSULTANT_AVAILABILITY_REPORT = "/mips/core/report-embed/consultantAvailabilityChart";
    static readonly ROUTE_REPORT_LEAVE_DETAILS = "/mips/core/report-embed/leaveDetails";

    // MISC endpoints
    static readonly ROUTE_INTRANET_PAGE = 'https://thorogoodassociates.sharepoint.com/SitePages/Thorogood%20Intranet.aspx';

    // Project Details Endpoints
    static readonly ROUTE_PROJECT_DETAILS = "/mips/core/project-details/"
    static readonly ROUTE_PROJECT_DETAILS_VIEW = "view";
    static readonly ROUTE_PROJECT_DETAILS_ADD = "add";
    static readonly ROUTE_PROJECT_DETAILS_EDIT = "edit";

}
